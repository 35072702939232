var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("sidebar.chat-templates")))])]},proxy:true},{key:"headerAction",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.modalTemplates.show()}}},[_vm._v(_vm._s(_vm.$t("app.new-2")))])]},proxy:true},{key:"body",fn:function(){return [(_vm.messengerLoadingUpload)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary","label":"Subiendo"}}),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.$t("app.upload"))+" ")])],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"table-responsive",attrs:{"md":"12"}},[_c('b-table',{staticClass:"table mb-0 table-borderless",attrs:{"items":_vm.getTemplates,"fields":_vm.columns,"busy":!Array.isArray(_vm.getTemplates) || _vm.getTemplates.length === 0
                    ? true
                    : false || _vm.messengerLoading == true},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[(_vm.messengerLoading)?_c('div',[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}}),_c('strong',[_vm._v(_vm._s(_vm.$t("app.loading"))+"...")])],1):_c('span',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t("app.not-found")))])])]},proxy:true},{key:"cell(avatar)",fn:function(data){return [_c('div',{staticClass:"profile-img-edit w-50 h-100"},[_c('b-img',{attrs:{"src":data.item.avatar
                          ? data.item.avatar
                          : require(`@/assets/images/user/user-group.png`),"width":"90","height":"90","alt":"avatar"}}),_c('div',{staticClass:"p-image"},[_c('div',{staticClass:"position-relative cursos-pointer",on:{"click":function($event){return _vm.openAvatarPickerTemplate(data.item.id)}}},[_c('i',{staticClass:"ri-pencil-line upload-button"})])])],1)]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{staticClass:"mr-1",attrs:{"variant":" iq-bg-warning rounded","size":"sm"},on:{"click":function($event){return _vm.$refs.modalTemplates.show(data.item)}}},[_c('i',{staticClass:"ri-ball-pen-fill ml-1"})]),_c('b-button',{attrs:{"variant":" iq-bg-danger rounded","size":"sm"},on:{"click":function($event){return _vm.deleteItem(data.item.id)}}},[_c('i',{staticClass:"ri-delete-bin-line ml-1"})])]}}])})],1)],1)]},proxy:true}])},[_c('span',{staticStyle:{"display":"none"},attrs:{"id":"pick-image"}},[_vm._v(" Select File ")]),_c('avatar-cropper',{attrs:{"upload-handler":_vm.selectedFile,"trigger":"#pick-image","labels":{
            submit: _vm.$t('app.accept'),
            cancel: _vm.$t('app.cancel'),
          },"cropper-options":{
            aspectRatio: 1,
            autoCropArea: 1,
            viewMode: 1,
            movable: false,
            zoomable: false,
          }},on:{"changed":_vm.setFileName}})],1)],1)],1),_c('modal-form',{ref:"modalTemplates",on:{"refresh":function($event){return _vm.getData()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }