<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("sidebar.chat-templates") }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="$refs.modalTemplates.show()">{{
              $t("app.new-2")
            }}</b-button>
          </template>
          <template v-slot:body>
            <div v-if="messengerLoadingUpload" class="text-center">
              <b-spinner
                style="width: 3rem; height: 3rem"
                variant="primary"
                label="Subiendo"
              >
              </b-spinner>
              <br />
              <span>
                {{ $t("app.upload") }}
              </span>
            </div>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table
                  class="table mb-0 table-borderless"
                  :items="getTemplates"
                  :fields="columns"
                  :busy="
                    !Array.isArray(getTemplates) || getTemplates.length === 0
                      ? true
                      : false || messengerLoading == true
                  "
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="messengerLoading">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong>{{ $t("app.loading") }}...</strong>
                      </div>
                      <span class="h5" v-else>{{ $t("app.not-found") }}</span>
                    </div>
                  </template>

                  <template v-slot:cell(avatar)="data">
                    <div class="profile-img-edit w-50 h-100">
                      <b-img
                        :src="
                          data.item.avatar
                            ? data.item.avatar
                            : require(`@/assets/images/user/user-group.png`)
                        "
                        class=""
                        width="90"
                        height="90"
                        alt="avatar"
                      ></b-img>
                      <div class="p-image">
                        <div
                          class="position-relative cursos-pointer"
                          @click="openAvatarPickerTemplate(data.item.id)"
                        >
                          <i class="ri-pencil-line upload-button"></i>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      variant=" iq-bg-warning rounded"
                      class="mr-1"
                      size="sm"
                      @click="$refs.modalTemplates.show(data.item)"
                      ><i class="ri-ball-pen-fill ml-1"></i
                    ></b-button>
                    <b-button
                      @click="deleteItem(data.item.id)"
                      variant=" iq-bg-danger rounded"
                      size="sm"
                      ><i class="ri-delete-bin-line ml-1"></i
                    ></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <span style="display: none" id="pick-image"> Select File </span>
          <avatar-cropper
            :upload-handler="selectedFile"
            @changed="setFileName"
            trigger="#pick-image"
            :labels="{
              submit: $t('app.accept'),
              cancel: $t('app.cancel'),
            }"
            :cropper-options="{
              aspectRatio: 1,
              autoCropArea: 1,
              viewMode: 1,
              movable: false,
              zoomable: false,
            }"
          />
        </iq-card>
      </b-col>
    </b-row>
    <modal-form ref="modalTemplates" @refresh="getData()" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalForm from "./modalForm.vue";
import { mapActions, mapGetters } from "vuex";
import AvatarCropper from "vue-avatar-cropper";
import _ from "lodash";

export default {
  name: "ChatTemplates",
  components: {
    AvatarCropper,
    modalForm,
  },
  data() {
    return {
      fileSelected: null,
      form: {
        avatar: null,
      },
      currentTemplateId: null,
    };
  },
  mounted() {
    core.index();
    this.getData();
  },
  methods: {
    ...mapActions({
      getChatTemplatesAuthCollege: "getChatTemplatesAuthCollege",
      deleteChatTemplate: "deleteChatTemplate",
      uploadAvatarChatTemplate: "uploadAvatarChatTemplate",
    }),
    getData() {
      this.getChatTemplatesAuthCollege(this.filter);
    },
    openAvatarPickerTemplate(templateId) {
      this.currentTemplateId = templateId;
      document.getElementById("pick-image").click();
    },
    setFileName(file) {
      this.fileSelected = file;
      this.form.avatar = file;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.form.avatar = file;
      await this.uploadAvatar(dataUrl);
    },
    async uploadAvatar(preview) {
      const resp = await this.uploadAvatarChatTemplate({
        preview,
        avatar: this.form.avatar,
        chat_template_id: this.currentTemplateId,
      });
      if (resp?.status == 200) {
        core.showSnackbar(
          "success",
          this.$t("chat-template.avatar-successfully")
        );
      }
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteChatTemplate(id);
            this.getData();
            core.showSnackbar("success", this.$t("app.delete-success"));
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  computed: {
    ...mapGetters({
      getTemplates: "chatTemplates",
      messengerLoading: "messengerLoading",
      messengerLoadingUpload: "messengerLoadingUpload",
    }),
    columns() {
      return [
        {
          label: this.$t("chat-template.avatar"),
          key: "avatar",
          class: "text-center",
        },
        {
          label: this.$t("chat-template.name"),
          key: "name",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
